table thead tr th {
  &:first-child {
    @apply w-16
  }
}

#request-table thead tr th {
  &:first-child {
    @apply lg:w-0 w-16
  }
  &:nth-child(5) {
    @apply lg:w-24
  }
  &:nth-child(6) {
    @apply lg:w-32
  }
  &:nth-child(7) {
    @apply lg:w-48
  }
}