@import "~@boomrank/react-components/src";

#root {
  background-color: white;
}

.easteregg .dashboard .headline {
  background-image: linear-gradient(180deg, rgba(53, 182, 173, 0.2), rgba(255, 255, 255, 0.1)), url('https://media.tenor.com/Vyaz82AA72oAAAAd/valter-sunburnt.gif');
  background-position: right;
}

.beta {
  .dashboard .headline {
    background-image: None;
    @apply bg-br-red;
  }
}